import * as React from 'react';

import styles from './textarea.module.css';

export const TextArea = ({
  className,
  ...props
}: React.ComponentProps<'textarea'>) => {
  const classNames = [styles.textarea, className].filter(c => c).join(' ');

  return <textarea className={classNames} {...props} />;
};
