import * as React from 'react';
import InputMask from 'react-input-mask';

import { Button } from '../Button';
import { Input } from '../Input';
import { TextArea } from '../TextArea';
import { initialFormState, formReducer } from './reducer';
import styles from './contact-us-form.module.css';

/**
 * Our honeypot field is currently disguised as `lastName`.
 * We do not currently capture a `lastName` so it makes for a good honeypot field.
 * ie. Bots will fill out the honeypot field, and we can reject those submissions
 * because we know a bot filled them out. If we need to add lastName, we need to change
 * our honey pot field.
 */
interface Fields {
  email: string;
  lastName?: never; // This is our honeypot field! It should never be filled out!
  message: string;
  name: string;
  phoneNumber: string;
}

interface ContactUsFormProps {
  children?: React.ReactNode;
}

const encode = (data: { [key: string]: string | undefined }) =>
  Object.keys(data)
    .map(key => {
      const value = data[key];

      return value
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        : null;
    })
    .filter(v => v)
    .join('&');

const FORM_NAME = 'Contact Us';
const HONEYPOT_FIELD = 'lastName'; // Used for spam protection.

export const ContactUsForm = ({ children }: ContactUsFormProps) => {
  const [formState, dispatch] = React.useReducer(formReducer, initialFormState);

  const { status } = formState;

  const [fields, setFields] = React.useState<Fields>({
    email: '',
    message: '',
    name: '',
    phoneNumber: '',
  });

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;

    setFields({ ...fields, [name]: value });
  };

  const onComplete = () => {
    setFields({
      email: '',
      message: '',
      name: '',
      phoneNumber: '',
      [HONEYPOT_FIELD]: undefined,
    });
    dispatch({ type: 'SUCCESS' });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // Prevent the default.
    e.preventDefault();

    // Set status to "loading".
    dispatch({ type: 'LOADING' });

    // Cleanup data.
    const data: Fields = {
      email: fields.email.trim(),
      message: fields.message.trim(),
      name: fields.name.trim(),
      phoneNumber: fields.phoneNumber.trim(),
      [HONEYPOT_FIELD]: fields[HONEYPOT_FIELD],
    };

    // Do work.
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': FORM_NAME, ...data }),
    })
      .then(res => {
        if (res.ok) {
          onComplete();
        } else {
          // Dispatch error.
          console.log(res);
        }
      })
      .catch(error => {
        // Dispatch error.
        console.log(error);
      });
  };

  const completed = status === 'success';
  const isLoading = status === 'loading';

  return (
    <div className={styles.container}>
      {completed ? (
        <div className={styles.message}>
          Thank you for submitting your information. We'll be in contact
          shortly!
        </div>
      ) : (
        <form
          className={styles.form}
          onSubmit={onSubmit}
          name={FORM_NAME}
          data-netlify="true"
          data-netlify-honeypot={HONEYPOT_FIELD}
        >
          <div className={styles.row}>
            <div className={styles.field}>
              <label className={styles.label} htmlFor="name">
                Name
              </label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                aria-label="Name"
                autoComplete="name"
                value={fields.name}
                onChange={onInputChange}
                disabled={isLoading}
                required
                minLength={2}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <label className={styles.label} htmlFor="email">
                Email
              </label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                aria-label="Email"
                autoComplete="email"
                value={fields.email}
                onChange={onInputChange}
                disabled={isLoading}
                required
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label} htmlFor="phoneNumber">
                Phone number
              </label>
              <InputMask
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Phone number"
                aria-label="Phone number"
                autoComplete="tel-national"
                value={fields.phoneNumber}
                onChange={onInputChange}
                disabled={isLoading}
                required
                minLength={10}
                pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
                mask="(999) 999-9999"
              >
                {(inputProps: React.ComponentProps<'input'>) => (
                  <Input {...inputProps} />
                )}
              </InputMask>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <label className={styles.label} htmlFor="message">
                Message
              </label>
              <TextArea
                className={styles.textarea}
                name="message"
                id="message"
                placeholder="Message"
                aria-label="Message"
                value={fields.message}
                onChange={onInputChange}
                disabled={isLoading}
                required
                rows={6}
              />
            </div>
            {/* Honeypot field! */}
            <div className={`${styles.field} hidden`}>
              <label
                className={styles.label}
                htmlFor={HONEYPOT_FIELD}
                aria-hidden="true"
              >
                Last name
              </label>
              <Input
                type="text"
                name={HONEYPOT_FIELD}
                id={HONEYPOT_FIELD}
                placeholder="Last name"
                aria-hidden="true"
                value={fields.lastName}
                onChange={onInputChange}
                minLength={2}
              />
            </div>
            {/* End Honeypot */}
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <Button type="submit" disabled={isLoading} block>
                {isLoading ? 'Submitting...' : 'Send message'}
              </Button>
            </div>
          </div>

          {children}
        </form>
      )}
    </div>
  );
};
