import * as React from 'react';
import { graphql } from 'gatsby';

import { ContactUsQuery } from '../../graphql-types';
import { formatHoursOfOperation } from '../helpers/formatHoursOfOperation';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { Address } from '../components/Address';
import { formatPhoneNumber } from '../helpers/formatPhoneNumber';
import { Map } from '../components/Map';
import { ContactUsForm } from '../components/ContactUsForm';
import { GoToLink } from '../components/GoToLink';

import locations from '../../content/locations.json';
import styles from './contact-us.module.css';

interface ContactUsProps {
  data: ContactUsQuery;
}

const ContactUs = ({ data }: ContactUsProps) => {
  const org = data.site?.siteMetadata?.organization;

  return (
    <Layout>
      <SEO title="Contact us" />

      <Section>
        <Container>
          <h1>Contact us</h1>
          <p>
            Your success and happiness means everything to us. If you don't
            succeed, we don't succeed. Have a question? Ready to{' '}
            <Link to="/first-appointment">schedule your first appointment</Link>
            ? We're here to help.
          </p>

          {locations &&
            locations.length === 1 &&
            locations.map(l => {
              const hours = formatHoursOfOperation(l.hoursOfOperation);
              return (
                <div key={l.id} className={styles.location}>
                  <h2>{l.name}</h2>
                  {hours && hours.length && (
                    <>
                      <h3>Office hours</h3>
                      {hours.map(h => (
                        <p>{h}</p>
                      ))}
                    </>
                  )}

                  {l?.phoneNumber ? (
                    <>
                      <h3>Call</h3>
                      <p>
                        <a href={`tel:${l.phoneNumber}`}>
                          {formatPhoneNumber(l.phoneNumber)}
                        </a>
                      </p>
                    </>
                  ) : null}

                  {l?.email ? (
                    <>
                      <h3>Email</h3>
                      <p>
                        <a href={`mailto:${l.email}`}>{l.email}</a>
                      </p>
                    </>
                  ) : null}

                  {l?.address ? (
                    <>
                      <h3>Visit</h3>

                      <Address address={l.address} />
                    </>
                  ) : null}

                  <Map height={400} googlePlaceId={l.googlePlaceId} />
                </div>
              );
            })}
          {locations && locations.length > 1 ? (
            <>
              <h3>Looking for a location?</h3>
              <GoToLink to="/locations">See our locations</GoToLink>
            </>
          ) : (
            <>
              {org?.phoneNumber ? (
                <>
                  <h3>Call</h3>
                  <p>
                    <a href={`tel:${org.phoneNumber}`}>
                      {formatPhoneNumber(org.phoneNumber)}
                    </a>
                  </p>
                </>
              ) : null}

              {org?.email ? (
                <>
                  <h3>Email</h3>
                  <p>
                    <a href={`mailto:${org.email}`}>{org.email}</a>
                  </p>
                </>
              ) : null}

              {org?.address ? (
                <>
                  <h3>Visit</h3>

                  <Address recipient={org?.name} address={org.address} />
                </>
              ) : null}

              <Map height={400} />
            </>
          )}
        </Container>
      </Section>
      <Section altBackground>
        <Container>
          <h2>Get in contact with us.</h2>
          <ContactUsForm />
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ContactUs {
    site {
      siteMetadata {
        organization {
          name
          phoneNumber
          email
          address {
            city
            secondaryAddress
            state
            streetAddress
            zipcode
          }
        }
      }
    }
  }
`;

export default ContactUs;
